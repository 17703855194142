<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <company-title />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tax-office />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tax-no />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <country />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <city />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <phone />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <fax />
      </b-col>
      <b-col
        cols="12"
      >
        <email />
      </b-col>
      <b-col
        cols="12"
      >
        <address-text />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import CompanyTitle from '@/views/Admin/Config_company/elements/CompanyTitle.vue'
import TaxOffice from '@/views/Admin/Config_company/elements/TaxOffice.vue'
import TaxNo from '@/views/Admin/Config_company/elements/TaxNo.vue'
import Country from '@/views/Admin/Config_company/elements/Country.vue'
import City from '@/views/Admin/Config_company/elements/City.vue'
import AddressText from '@/views/Admin/Config_company/elements/Address.vue'
import Phone from '@/views/Admin/Config_company/elements/Phone.vue'
import Fax from '@/views/Admin/Config_company/elements/Fax.vue'
import Email from '@/views/Admin/Config_company/elements/Email.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    CompanyTitle,
    TaxOffice,
    TaxNo,
    Country,
    City,
    AddressText,
    Phone,
    Fax,
    Email,
  },
}
</script>
