<template>
  <b-form-group
    label="Ülke"
    label-for="id_countries"
  >
    <validation-provider
      #default="{ errors }"
      name="Ülke"
      rules="required"
    >
      <v-select
        id="id_countries"
        v-model="dataItem.id_countries"
        :options="countries"
        label="title"
        :reduce="country => country.id"
        placeholder="Seçiniz"
        @input="getCities"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Company',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['configCompany/dataItem']
    },
    countries() {
      return this.$store.getters['countries/dataList']
    },
  },
  created() {
    this.getCountries()
    localize('tr')
  },
  mounted() {
    this.getCities()
  },
  methods: {
    getCountries() {
      this.$store.dispatch('countries/getDataList', {
        select: [
          'countries.id AS id',
          'countries.title AS title',
        ],
        order_by: ['countries.is_default', 'ASC'],
      })
    },
    getCities() {
      this.dataItem.id_cities = this.dataItem.id_cities ? null : this.dataItem.id_cities
      this.$store.dispatch('cities/getDataList', {
        select: [
          'cities.id AS id',
          'cities.title AS title',
        ],
        where: {
          'cities.id_countries': this.dataItem.id_countries,
        },
      })
    },
  },
}
</script>
